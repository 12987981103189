import Vue from 'vue'
 
const Icon = () => import('./index.vue')
 
const components = {
  Icon
}
 
Object.keys(components).forEach(item => {
  Vue.component(item, components[item])
})
