const Layout = () => import("@/layout");
const Home = () => import("@/views/home");
const About = () => import("@/views/about");
const Digital = () => import("@/views/digital");
const Saas = () => import("@/views/saas");
const Deal = () => import("@/views/deal");
const Goldmatirx = () => import("@/views/goldmatrix");
const IntellIgent = () => import("@/views/intelligent");
const Company = () => import("@/views/company");
const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    children: [
      { path: "/home", name: "Home", component: Home },
      { path: "/about", name: "About", component: About },
      { path: "/service/digital", component: Digital },
      { path: "/service/saas", component: Saas },
      { path: "/service/trade", component: Deal },
      { path: "/service/goldmatrix", component: Goldmatirx },
      { path: "/service/intelligent", component: IntellIgent },
      { path: "/service/company", component: Company },
    ],
  },
];
export default routes;
